/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        $(".banner").headroom({
          onTop : function() {
            $('body').removeClass('header-fixed');
            $('.navbar-header').slideDown();
            $('.small-brand').fadeOut();
            $('.collapsed-locations').fadeOut();
          },
          onNotTop : function() {
            $('body').addClass('header-fixed');
            $('.navbar-header').slideUp();
            $('.small-brand').fadeIn();
            $('.collapsed-locations').fadeIn();
          },
        });

        $('a[href*=\\#]:not([href=\\#])').click(function() {
          var target = $(this.hash);
          if (target.length) {
            $('html,body').animate({
              scrollTop: target.offset().top - 50
            }, 1000);
            return false;
          }
        });

        $('.back-to-top-link').on('click', function (e) {
          e.preventDefault();
          $('html,body').animate({
            scrollTop: 0
          }, 1000);
        });

        $('a.next-section').click(function() {
          var target = $(this).parents('.home-content-row').next('.home-content-row');
          if (target.length) {
            $('html,body').animate({
              scrollTop: target.offset().top - 50
            }, 1000);
            return false;
          }
        });

        // Collapsed locations
        $('.collapsed-locations').mouseenter(function() {
          $(this).addClass('open-menu');
        }).mouseleave(function() {
          $(this).removeClass('open-menu');
        });

        // Masonry
        // with jQuery
        $gridContainer = $('.grid');

        // initialize Masonry after all images have loaded  
        $gridContainer.imagesLoaded( function() {
          $gridContainer.masonry({
            columnWidth: 250,
            itemSelector: '.grid-item',
            gutter: 5,
            isFitWidth: true,
            animate: false
          });
        });

        $( window ).on( "orientationchange", function( event ) {
          $gridContainer.masonry('destroy');
          $gridContainer.masonry({
            columnWidth: 250,
            itemSelector: '.grid-item',
            gutter: 5,
            isFitWidth: true,
            animate: false
          });
        });

        $( '.grid-item a' ).swipebox();


        // Resize stuff
        var waitForFinalEvent = (function () {
          var timers = {};
          return function (callback, ms, uniqueId) {
            if (!uniqueId) {
              uniqueId = "Don't call this twice without a uniqueId";
            }
            if (timers[uniqueId]) {
              clearTimeout (timers[uniqueId]);
            }
            timers[uniqueId] = setTimeout(callback, ms);
          };
        })();

        $(window).resize(function(){

          // Home page slides
          waitForFinalEvent(function(){
            // $('.banner').sticky('update');

            if ($(window).width() <= 530) {
              $gridContainer.masonry({
                columnWidth: 250,
                itemSelector: '.grid-item',
                gutter: 5,
                isFitWidth: true,
                animate: false
              });
            }
          }, 100, "set slide and scroller heights");

        }).resize();

        var weekday = new Array(7);
            weekday[0]=  "Sunday";
            weekday[1] = "Monday";
            weekday[2] = "Tuesday";
            weekday[3] = "Wednesday";
            weekday[4] = "Thursday";
            weekday[5] = "Friday";
            weekday[6] = "Saturday";

        var introTalkDates = $('select[name="intro-talk"]');
        if (introTalkDates.length > 0) {
          $.ajax({
            url: sageAJAX.ajaxurl + '?action=get_class_dates',
            type: 'GET',
            dataType: 'json'
          })
          .done(function(response) {
            $.each(response, function(key, value){
              var dateString = new Date(value.start_date);
              var select_value = value.start_date + ' ' + value.start_time + ' (' + value.location + ')';
              introTalkDates
                .append($("<option></option>")
                .attr("value",select_value)
                .text(select_value)); 
            });
            introTalkDates
              .append($("<option></option>")
              .attr("value","Send me additional dates")
              .text("Send me additional dates")); 
          });
        }
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        $('.testimonials-wrapper').slick({
          autoplay: true,
          autoplaySpeed: 8000,
          pauseOnHover: false
        });
        $('.teachers-wrapper').slick({
          autoplay: true,
          autoplaySpeed: 5000,
          pauseOnHover: false
        });
        $('.notes-wrapper').slick({
          autoplay: true,
          autoplaySpeed: 8000,
          pauseOnHover: false
        });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // FAQs page
    'page_template_template_faqs': {
      init: function() {

        $('.faq-each h2 a').click(function(e){
          e.preventDefault();
          var parentEl = $(this).parents('.faq-each');
          if ($('.answer-content', parentEl).is(':hidden')) {
            $('.answer-content').each(function(){
              $(this).slideUp();
            });
            $('.answer-content', parentEl).slideDown();
          } else {
            $('.answer-content', parentEl).slideUp();
          }
        });

      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
